import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSwapAndLimitContext, useSwapContext } from 'state/swap/hooks'
import styled from 'styled-components'
import { SwapTab } from 'uniswap/src/types/screens/interface'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import { SwapHeaderTabButton } from './styled'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 12px;
  padding-right: 4px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)<{ compact: boolean }>`
  gap: ${({ compact }) => (compact ? 0 : 16)}px;

  ${SwapHeaderTabButton} {
    ${({ compact }) => compact && 'padding: 8px 12px;'}
  }
`

const PathnameToTab: { [key: string]: SwapTab } = {
  '/swap': SwapTab.Swap,
  '/send': SwapTab.Send,
  '/limit': SwapTab.Limit,
}

export default function SwapHeader({ compact }: { compact: boolean }) {
  const { chainId, currentTab, setCurrentTab } = useSwapAndLimitContext()
  const {
    derivedSwapInfo: { trade, autoSlippage },
  } = useSwapContext()
  const { pathname } = useLocation()
  const [, setTriggerBuyFlow] = useState(false)

  useEffect(() => {
    setCurrentTab(PathnameToTab[pathname] ?? SwapTab.Swap)
    if (pathname === '/buy') {
      setTriggerBuyFlow(true)
    }
  }, [pathname, setCurrentTab])

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer compact={compact}></HeaderButtonContainer>
      {currentTab === SwapTab.Swap && (
        <RowFixed>
          <SettingsTab autoSlippage={autoSlippage} chainId={chainId} compact={compact} trade={trade.trade} />
        </RowFixed>
      )}
    </StyledSwapHeader>
  )
}
