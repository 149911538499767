import Column from 'components/Column'
import { Trans } from 'i18n'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { FeatureFlags } from 'uniswap/src/features/gating/flags'
import { useFeatureFlag } from 'uniswap/src/features/gating/hooks'
import { GitVersionRow } from './GitVersionRow'
import { SlideOutMenu } from './SlideOutMenu'
import { SmallBalanceToggle } from './SmallBalanceToggle'
import { SpamToggle } from './SpamToggle'

const Container = styled(Column)`
  height: 100%;
  justify-content: space-between;
`

const SectionTitle = styled(ThemedText.SubHeader)`
  color: ${({ theme }) => theme.neutral2};
  padding-bottom: 24px;
`

const ToggleWrapper = styled.div<{ currencyConversionEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: ${({ currencyConversionEnabled }) => (currencyConversionEnabled ? '10px' : '24px')};
`

export default function SettingsMenu({ onClose }: { onClose: () => void }) {
  const currencyConversionEnabled = useFeatureFlag(FeatureFlags.CurrencyConversion)

  return (
    <SlideOutMenu title={<Trans i18nKey="common.settings" />} onClose={onClose}>
      <Container>
        <div>
          <SectionTitle data-testid="wallet-header">
            <Trans i18nKey="common.preferences" />
          </SectionTitle>
          <ToggleWrapper currencyConversionEnabled={currencyConversionEnabled}>
            <SmallBalanceToggle />
            <SpamToggle />
          </ToggleWrapper>
        </div>
        <GitVersionRow />
      </Container>
    </SlideOutMenu>
  )
}
