import { MenuItem } from 'components/NavBar/CompanyMenu/Content'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { FeatureFlags } from 'uniswap/src/features/gating/flags'
import { useFeatureFlag } from 'uniswap/src/features/gating/hooks'

export type TabsSection = {
  title: string
  href: string
  isActive?: boolean
  items?: TabsItem[]
  closeMenu?: () => void
}

export type TabsItem = MenuItem & {
  icon?: JSX.Element
  quickKey: string
}

export const useTabsContent = (): TabsSection[] => {
  const { t } = useTranslation()
  const isLegacyNav = !useFeatureFlag(FeatureFlags.NavRefresh)
  const { pathname } = useLocation()

  return isLegacyNav
    ? [
        {
          title: t('common.swap'),
          href: '/swap',
        },
        {
          title: t('common.explore'),
          href: '/explore',
        },
        {
          title: t('common.nfts'),
          href: '/nfts',
        },
      ]
    : [
        {
          title: t('common.swap'),
          href: '/swap',
          isActive: pathname.startsWith('/swap'),
        },
        {
          title: t('common.pool'),
          href: '/pool',
          isActive: pathname.startsWith('/pool'),
        },
        {
          title: t('common.explore'),
          href: '/explore',
          isActive: pathname.startsWith('/explore'),
        },
      ]
}
