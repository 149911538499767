import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import { exploreSearchStringAtom, sortAscendingAtom, sortMethodAtom, TokenSortMethod } from 'components/Tokens/state'
import { chainIdToBackendChain, InterfaceGqlChain } from 'constants/chains'
import BULLDOG_TOKEN_LIST from 'constants/tokenLists/bullDog.tokenlist.json'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useAtomValue } from 'jotai/utils'
import { useMemo } from 'react'
import {
  Chain,
  Currency,
  Exact,
  InputMaybe,
  Scalars,
  TokenWebQuery,
  TopTokens100Query,
} from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import {
  PollingInterval,
  PricePoint,
  supportedChainIdFromGQLChain,
  unwrapToken,
  usePollQueryWhileMounted,
} from './util'

const TokenSortMethods = {
  [TokenSortMethod.PRICE]: (a: TopToken, b: TopToken) =>
    (b?.market?.price?.value ?? 0) - (a?.market?.price?.value ?? 0),
  [TokenSortMethod.DAY_CHANGE]: (a: TopToken, b: TopToken) =>
    (b?.market?.pricePercentChange1Day?.value ?? 0) - (a?.market?.pricePercentChange1Day?.value ?? 0),
  [TokenSortMethod.HOUR_CHANGE]: (a: TopToken, b: TopToken) =>
    (b?.market?.pricePercentChange1Hour?.value ?? 0) - (a?.market?.pricePercentChange1Hour?.value ?? 0),
  [TokenSortMethod.VOLUME]: (a: TopToken, b: TopToken) =>
    (b?.market?.volume?.value ?? 0) - (a?.market?.volume?.value ?? 0),
  [TokenSortMethod.FULLY_DILUTED_VALUATION]: (a: TopToken, b: TopToken) =>
    (b?.project?.markets?.[0]?.fullyDilutedValuation?.value ?? 0) -
    (a?.project?.markets?.[0]?.fullyDilutedValuation?.value ?? 0),
}

function useSortedTokens(tokens?: TopToken[]) {
  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)

  return useMemo(() => {
    if (!tokens) {
      return undefined
    }
    const tokenArray = Array.from(tokens).sort(TokenSortMethods[sortMethod])

    return sortAscending ? tokenArray.reverse() : tokenArray
  }, [tokens, sortMethod, sortAscending])
}

function useFilteredTokens(tokens?: TopToken[]) {
  const filterString = useAtomValue(exploreSearchStringAtom)

  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString])

  return useMemo(() => {
    if (!tokens) {
      return undefined
    }
    let returnTokens = tokens
    if (lowercaseFilterString) {
      returnTokens = returnTokens?.filter((token) => {
        const addressIncludesFilterString = token?.address?.toLowerCase().includes(lowercaseFilterString)
        const projectNameIncludesFilterString = token?.project?.name?.toLowerCase().includes(lowercaseFilterString)
        const nameIncludesFilterString = token?.name?.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = token?.symbol?.toLowerCase().includes(lowercaseFilterString)
        return (
          projectNameIncludesFilterString ||
          nameIncludesFilterString ||
          symbolIncludesFilterString ||
          addressIncludesFilterString
        )
      })
    }
    return returnTokens
  }, [tokens, lowercaseFilterString])
}

type CustomizedTokenWebQueryVariables = Exact<{
  chain: Chain
  address?: InputMaybe<Scalars['String']>
}>

type CustomizedTokenWebQuery = {
  __typename?: 'Query'
  token?: {
    __typename?: 'Token'
    id: string
    decimals?: number
    name?: string
    chain: Chain
    address?: string
    symbol?: string
    market?: {
      __typename?: 'TokenMarket'
      id: string
      totalValueLocked?: {
        __typename?: 'Amount'
        id: string
        value: number
        currency?: Currency
      }
      price?: {
        __typename?: 'Amount'
        id: string
        value: number
        currency?: Currency
      }
      pricePercentChange1Hour?: {
        __typename?: 'Amount'
        id: string
        currency?: Currency
        value: number
      }
      pricePercentChange1Day?: {
        __typename?: 'Amount'
        id: string
        currency?: Currency
        value: number
      }
      volume24H?: {
        __typename?: 'Amount'
        id: string
        value: number
        currency?: Currency
      }
      priceHigh52W?: {
        __typename?: 'Amount'
        id: string
        value: number
      }
      priceLow52W?: {
        __typename?: 'Amount'
        id: string
        value: number
      }
    }
    project?: {
      __typename?: 'TokenProject'
      id: string
      name?: string
      description?: string
      homepageUrl?: string
      twitterName?: string
      logoUrl?: string
      tokens: Array<{
        __typename?: 'Token'
        id: string
        chain: Chain
        address?: string
      }>
      markets?: Array<
        | {
            __typename?: 'TokenProjectMarket'
            id: string
            fullyDilutedValuation?: {
              __typename?: 'Amount'
              id: string
              value: number
              currency?: Currency
            }
            marketCap?: {
              __typename?: 'Amount'
              id: string
              value: number
              currency?: Currency
            }
          }
        | undefined
      >
    }
  }
}

const CustomizedTokenWebDocument = gql`
  query TokenWeb($chain: Chain!, $address: String = null) {
    token(chain: $chain, address: $address) {
      id
      decimals
      name
      chain
      address
      symbol
      standard
      market(currency: USD) {
        id
        totalValueLocked {
          id
          value
          currency
        }
        price {
          id
          value
          currency
        }
        pricePercentChange1Hour: pricePercentChange(duration: HOUR) {
          id
          currency
          value
        }
        pricePercentChange1Day: pricePercentChange(duration: DAY) {
          id
          currency
          value
        }
        volume: volume(duration: DAY) {
          id
          value
          currency
        }
        priceHigh52W: priceHighLow(duration: YEAR, highLow: HIGH) {
          id
          value
        }
        priceLow52W: priceHighLow(duration: YEAR, highLow: LOW) {
          id
          value
        }
      }
      project {
        id
        name
        description
        homepageUrl
        twitterName
        logoUrl
        tokens {
          id
          chain
          address
        }
        markets(currencies: [USD]) {
          id
          fullyDilutedValuation {
            id
            value
            currency
          }
          marketCap {
            id
            value
            currency
          }
        }
      }
    }
  }
`

const defaultOptions = {} as const

function useCustomizedTokenWebQuery(
  baseOptions: Apollo.QueryHookOptions<CustomizedTokenWebQuery, CustomizedTokenWebQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomizedTokenWebQuery, CustomizedTokenWebQueryVariables>(CustomizedTokenWebDocument, options)
}

export type SparklineMap = { [key: string]: PricePoint[] | undefined }
export type TopToken = NonNullable<NonNullable<TopTokens100Query>['topTokens']>[number]

interface UseTopTokensReturnValue {
  tokens?: readonly TopToken[]
  tokenSortRank: Record<string, number>
  loadingTokens: boolean
}

type tokenInfo = {
  chain: Chain
  address: string
}

const getCustomizedTokenWebQueryArray = (addresses: (tokenInfo | undefined)[], isWindowVisible: boolean) => {
  return addresses.map((address) => {
    const chainId = supportedChainIdFromGQLChain(address?.chain as InterfaceGqlChain)
    const overrideTokenWebQuery = BULLDOG_TOKEN_LIST.find((t) =>
      t.addresses.find((addr) => addr.chainId == chainId && addr.address == address?.address)
    )
      ?.addresses.map(
        (addr) =>
          addr as {
            chainId: number
            address: string
            override?: { tokenWebQuery: TokenWebQuery }
          }
      )
      .find((addr) => addr.chainId == chainId)?.override?.tokenWebQuery

    const { data } = usePollQueryWhileMounted(
      useCustomizedTokenWebQuery({
        variables: { chain: address?.chain ?? Chain.Ethereum, address: address?.address },
        skip: !isWindowVisible || !address,
      }),
      PollingInterval.Slow
    )
    if (overrideTokenWebQuery) {
      return {
        __typename: 'Query',
        token: overrideTokenWebQuery,
      } as CustomizedTokenWebQuery
    }
    return data
  })
}

export function useTopTokens(chain: Chain): UseTopTokensReturnValue {
  const chainId = supportedChainIdFromGQLChain(chain)
  const isWindowVisible = useIsWindowVisible()

  const tokenList = BULLDOG_TOKEN_LIST.map((t) =>
    t.addresses.find((addr) => addr.chainId == chainId) != undefined ? t : undefined
  )
  const tokenInfoList = tokenList
    .map((t) =>
      t != undefined
        ? ({
            chain: chainIdToBackendChain({ chainId: t.infoChainId }),
            address: t.addresses.find((addr) => addr.chainId == t.infoChainId)?.address,
          } as tokenInfo)
        : undefined
    )
    .map((t) => (t?.address != undefined ? t : undefined))
  const data = getCustomizedTokenWebQueryArray(tokenInfoList, isWindowVisible)

  const unwrappedTokens = useMemo(
    () =>
      chainId &&
      data
        ?.filter((token) => token !== undefined && token.token !== null)
        .map((token) => unwrapToken(chainId, token?.token))
        .map((t) => {
          return {
            ...t,
            chain,
            address: tokenList
              .find(
                (tl) =>
                  tl?.addresses.find((addr) => addr.address.toLowerCase() == t?.address?.toLocaleLowerCase()) !=
                  undefined
              )
              ?.addresses.find((addr) => addr.chainId == chainId)?.address,
          } as TopToken
        }),
    [chain, chainId, data, tokenList]
  )
  const sortedTokens = useSortedTokens(unwrappedTokens)
  const tokenSortRank = useMemo(
    () =>
      sortedTokens?.reduce((acc, cur, i) => {
        if (!cur?.address) {
          return acc
        }
        return {
          ...acc,
          [cur.address]: i + 1,
        }
      }, {}) ?? {},
    [sortedTokens]
  )
  const filteredTokens = useFilteredTokens(sortedTokens)
  return useMemo(
    () => ({ tokens: filteredTokens, tokenSortRank, loadingTokens: false }),
    [filteredTokens, tokenSortRank]
  )
}
