import Web3Status from 'components/Web3Status'
import { chainIdToBackendChain } from 'constants/chains'
import { useAccount } from 'hooks/useAccount'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Trans } from 'i18n'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { ReactNode } from 'react'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { StyledInternalLink } from 'theme/components'
import { Z_INDEX } from 'theme/zIndex'
import { Chain } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { ChainSelector } from '../ChainSelector'
import Blur from './Blur'
import * as styles from './style.css'

const Nav = styled.nav`
  position: relative;
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.sticky};
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId } = useAccount()
  const chainName = chainIdToBackendChain({ chainId, withFallback: true })

  const isPoolActive = useIsPoolsPage()

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
        <Trans i18nKey="common.swap" />
      </MenuItem>
      <MenuItem
        href={'/explore' + (chainName !== Chain.Ethereum ? `/${chainName.toLowerCase()}` : '')}
        isActive={pathname.startsWith('/explore')}
      >
        <Trans i18nKey="common.explore" />
      </MenuItem>
      <Box display={{ sm: 'flex', lg: 'none', xxl: 'flex' }} width="full">
        <MenuItem href="/pool" dataTestId="pool-nav-link" isActive={isPoolActive}>
          <Trans i18nKey="common.pool" />
        </MenuItem>
      </Box>
    </>
  )
}

const LegacyNavbar = ({ blur }: { blur: boolean }) => {
  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <StyledInternalLink to="/explore">
              <img src={`${process.env.PUBLIC_URL}/images/App_Pages_Icon.png`} alt="BulldogIcon" />
            </StyledInternalLink>
            <Box display={{ sm: 'flex', lg: 'none' }}>
              <ChainSelector leftAlign={true} />
            </Box>
            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Box display={{ sm: 'none', lg: 'flex' }}>
                <ChainSelector />
              </Box>
              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default LegacyNavbar
